.spinner-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  text-align: center;
}

.ant-radio-button-wrapper:first-child {
  border-bottom-left-radius: 6px !important;
  border-top-left-radius: 6px !important;
}

.ant-radio-button-wrapper:last-child {
  border-bottom-right-radius: 6px !important;
  border-top-right-radius: 6px !important;
}

.ant-btn,
.ant-pagination-item-link,
.ant-picker-cell-inner::before {
  border-radius: 6px !important;
}

.ant-input,
.ant-input-password,
.ant-select-selector,
.ant-picker,
.ant-pagination-item {
  border-radius: 6px !important;
  background-color: #e9f4e9 !important;
}

.ant-form-item-label {
  padding: 0 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.ant-spin-dot-item {
  opacity: 1;
}

.ant-spin-dot-item:first-child {
  background: #0950a1;
}

.ant-spin-dot-item:nth-child(2) {
  background: #bbe3e3;
}

.ant-spin-dot-item:nth-child(3) {
  background: #00afb7;
}

.ant-spin-dot-item:nth-child(4) {
  background: #e9f4e9;
}